.mainteance-circle-wrapper {
  //   width: 90%;
  margin: 0 auto;
  & > * {
    // width: 30%;
    // height: auto;
    margin-right: 5rem;
    margin-bottom: 5rem;
    @include respond(laptop-sm) {
      margin-right: 0;
    }
  }
}

.mainteance_list_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & .mainteance_list {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    @include respond(laptop-sm) {
      flex-direction: column;
    }
    & .dotted {
      max-height: 25rem;
      max-width: 25rem;
      @include respond(laptop-sm) {
        margin-bottom: 3rem;
      }
    }
    &.content {
      @include respond(phone) {
        text-align: center;
      }
    }
  }
}

.mainstream_list_wrapper {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: flex-start;
  & .mainstream_list {
    margin-top: 2rem;
    display: flex;
    @include respond(laptop-md) {
      flex-direction: column;
      align-items: center;
    }
    & .dotted {
      height: 5rem;
      @include respond(laptop-sm) {
        margin-bottom: 3rem;
      }
    }
    &.content {
      @include respond(phone) {
        text-align: center;
      }
    }
  }
}
