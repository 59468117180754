@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// MEDIA QUERY MANAGER
@mixin respond($breakpoint) {
  @if $breakpoint == phone-xs {
    @media only screen and (max-width: 7.5em) {
      @content;
    } //280px
  }
  @if $breakpoint == phone-sm {
    @media only screen and (max-width: 20em) {
      @content;
    } //320px
  }
  @if $breakpoint == phone-md {
    @media only screen and (max-width: 23.4375em) {
      @content;
    } //375px
  }
  @if $breakpoint == phone {
    @media only screen and (max-width: 26.5625em) {
      @content;
    } //425px
  }
  @if $breakpoint == laptop-sm {
    @media only screen and (max-width: 48em) {
      @content;
    } //768px
  }
  @if $breakpoint == laptop-md {
    @media only screen and (max-width: 64em) {
      @content;
    } //1024px
  }
  @if $breakpoint == laptop {
    @media only screen and (max-width: 90em) {
      @content;
    } //1440px
  }
  @if $breakpoint == desktop {
    @media only screen and (min-width: 112.5em) {
      @content;
    } //1800
  }
  @if $breakpoint == big-desktop {
    @media only screen and (min-width: 300.5em) {
      @content;
    } //3000
  }
}
