.full-cycle-dev-circle-wrapper {
  width: 80%;
  margin: 0 auto;

  & > * {
    margin-right: 5rem;
    @include respond(laptop-md) {
      margin-right: 0;
      margin-bottom: 5rem;
    }
  }
}
