.about-page {
  & .about-second-sec {
    margin-top: 8rem;
    &_left {
    }
    &_right {
    }
  }
  & .about-team-sec {
    &_pic-circle {
      border-radius: 50%;
      background-color: #c3c3c3;
      height: 15rem;
      width: 15rem;
    }
  }
}
