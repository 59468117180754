ul {
  margin: 0;
  padding: 0;
}
.footer-section {
  background: #fff;
  position: relative;
  padding: 2rem 2rem 0 2rem;
}
.footer-cta {
  border-bottom: 0.1rem solid #373636;
}
.single-cta i {
  color: #ff5e14;
  font-size: 3rem;
  float: left;
  margin-top: 0.8rem;
}
.cta-text {
  padding-left: 1.5rem;
  display: inline-block;
}
.cta-text h4 {
  color: #fff;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 0.2rem;
}
.cta-text span {
  color: #757575;
  font-size: 1.5rem;
}
.footer-content {
  position: relative;
  z-index: 2;
  & ul li {
    color: #858b8f !important;
  }
}

.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 33.5rem;
  background-size: cover;
  background-position: 100% 100%;
}
.footer-logo {
  margin-bottom: 3rem;
}
.footer-logo img {
  max-width: 20rem;
}
.footer-text p {
  margin-bottom: 1.4rem;
  font-size: 1.4rem;
  color: #7e7e7e;
  line-height: 2.8rem;
}
.footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 2rem;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  margin-bottom: 2rem;
}
.footer-social-icon a {
  color: #fff;
  font-size: 1.56rem;
  margin-right: 1.5rem;
}
.footer-social-icon i {
  height: 4rem;
  width: 4rem;
  text-align: center;
  line-height: 3.8rem;
  border-radius: 50%;
}
.facebook-bg {
  background: #3b5998;
}
.twitter-bg {
  background: #55acee;
}
.google-bg {
  background: #dd4b39;
}
.footer-widget-heading h3 {
  color: #202020;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 4rem;
  position: relative;
}

.footer-widget ul li {
  display: block;
  font-size: 1.3rem;
  width: 50%;
  margin-bottom: 1.2rem;
}
.footer-widget ul li a:hover {
  color: #ff5e14;
}
.footer-widget ul li a {
  color: #878787 !important;
  text-transform: capitalize;
}
.subscribe-form {
  position: relative;
  overflow: hidden;
}
.subscribe-form input {
  width: 100%;
  padding: 1.4rem 2.8rem;
  background: white;
  border: 0.1rem solid #2e2e2e;
  color: #fff;
}
.subscribe-form button {
  position: absolute;
  right: 0;
  background: #ff5e14;
  padding: 1.3rem 2rem;
  border: 0.1rem solid #ff5e14;
  top: 0;
}
.subscribe-form button i {
  color: #fff;
  font-size: 2.2rem;
  transform: rotate(-6deg);
}
.copyright-area {
  padding: 2.5rem 0;
}
.copyright-text p {
  margin: 0;
  font-size: 1.4rem;
  color: #878787;
}
.copyright-text p a {
  color: #ff5e14;
}
.footer-menu {
  display: flex;
  justify-content: flex-end;
  & ul li a svg {
    width: 2rem;
    height: 2rem;
  }
  & .FaLinkedin {
    color: #0077b5;
  }
  & .FaFacebook {
    color: #3b5998;
  }
  & .FaTwitterSquare {
    color: #55acee;
  }
}
.footer-menu li {
  display: inline-block;
  margin-left: 2rem;
}
.footer-menu li:hover a {
  color: #ff5e14;
}
.footer-menu li a {
  font-size: 1.4rem;
  color: #878787;
}
