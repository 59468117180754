.timeline {
  max-width: 83rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;

  .animationWrapper {
    position: relative;
    // width: 38.6rem;
  }

  &::after {
    content: "";
    border: 0.2rem solid $theme-color;
    position: absolute;
    width: 0;
    height: 91%;
    top: 1.9rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    background-color: $theme-color !important;
  }

  & > :nth-child(odd) {
    & > .animationWrapper div {
      justify-content: flex-end;
      text-align: right;
    }
    & > .animationWrapper p {
      text-align: right;
    }
  }
  &__content {
    & > .animationWrapper .myDivWrapper div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & > .animationWrapper div {
      display: flex;
      align-items: center;
      @include respond(phone) {
        flex-direction: column;
      }
      & > :not(:last-child) {
        margin-right: 1rem;
      }
    }
    padding: 0 3rem;
    background-color: white;
    border-radius: 0.5rem;
    position: relative;
    width: 38.6rem;
    &::after {
      content: "";
      position: absolute;
      width: 2rem;
      height: 2rem;
      background-color: white;
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
    }
    &::before {
      content: "";
      font-size: 2rem !important;
      font-weight: 300;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 5.5rem;
      height: 5.5rem;
      background-color: white;
      border: 0.3rem solid $theme-color;
      border-radius: 50%;
      z-index: 99;
      transform: translateY(-50%);
      background-color: $theme-color !important;
      background-size: 4rem 4rem;
      background-position: center;
      background-repeat: no-repeat;
    }
    &.i1::before {
      background-image: url("./../../images/main/1.png");
    }
    &.i2::before {
      background-image: url("./../../images/main/2.png");
    }
    &.i3::before {
      background-image: url("./../../images/main/3.png");
    }
    &.i4::before {
      background-image: url("./../../images/main/4.png");
    }
    &.i5::before {
      background-image: url("./../../images/main/5.png");
    }
    &.i6::before {
      background-image: url("./../../images/main/6.png");
    }
    &.i7::before {
      background-image: url("./../../images/main/7.png");
    }
    &.i8::before {
      background-image: url("./../../images/main/5.png");
    }
    &.i9::before {
      background-image: url("./../../images/main/3.png");
    }

    &:nth-child(odd) {
      .content_p {
        @include respond(phone) {
          text-align: center;
        }
      }
      .content_tag {
        right: 0.5rem;
      }

      &::after {
        left: -1rem;
      }
      &::before {
        top: 24%;
        right: -5.6rem;
      }
    }

    &:nth-child(even) {
      margin-left: auto;
      .content_p {
        text-align: left;
        @include respond(phone) {
          text-align: center;
        }
      }
      &::after {
        right: -1rem;
      }
      &::before {
        top: 24%;
        left: -5.7rem;
      }
      .content_tag {
        left: 0.5rem;
      }
    }
  }
}

.content {
  &_tag {
    position: absolute;
    top: 0.5rem;
    padding: 0.6rem 1rem;
    background-color: #fcf3e3;
    border-radius: 0.3rem;
    font-weight: bold;
    font-size: 1.4rem;
    color: #1f1f1f;
  }
  &_date {
    margin-bottom: 1rem;
    font-weight: bold;
    font-size: 1.4rem;
    color: #848892;
  }
  &_p {
    color: #242e4c;
    max-width: 23rem;
    margin-bottom: 2rem;
  }
  &_link {
    display: inline-flex;
    text-decoration: none;
    align-items: center;
    font-weight: bold;
    font-size: 1.4rem;
    color: #1f1f1f;
    svg {
      margin-left: 0.5rem;
    }
    &:hover {
      color: royalblue;
      transition-duration: 300ms;
      svg path {
        fill: royalblue;
      }
    }
  }
}

@include respond(laptop-md) {
  .timeline {
    gap: 1.5rem;
    padding: 1rem;
    &::after {
      display: none;
    }

    &__content {
      width: 100%;
      &::after {
        display: none;
      }
      &::before {
        display: none;
      }
    }
  }
}
.image_wrapper {
  background: $theme-color;
  width: 7rem;
  height: 7rem;
  padding: 1rem;
  border-radius: 2rem;
  position: relative;
  z-index: 99;
  img {
    width: 64px;
    height: 64px;
  }
  &::before {
    content: "";
    z-index: -1;
    position: absolute;
    width: 55%;
    height: 0.3rem;
    background-color: $theme-color;
    border-radius: 2rem;
    top: 46%;
    // left: -41%;
  }
}
.content_wrapper {
  display: flex;
  flex-direction: column;
}

.timeline {
  &__content {
    &:nth-child(even) {
      .image_wrapper::before {
        left: -49%;
        @include respond(phone) {
          right: unset;
          left: unset;
        }
      }
    }
    &:nth-child(odd) {
      .image_wrapper::before {
        right: unset;
        right: -49%;
        @include respond(phone) {
          right: unset;
          left: unset;
        }
      }
    }
  }
}

.nav-tabs {
  border-bottom: 0 !important;
  .nav-item {
    button {
      border: none !important;
      color: #1f1f1f !important;
    }
    .active {
      border-bottom: 0.2rem solid $theme-color !important;
    }
  }
}

.triagle_card {
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
  .wrapper {
    display: flex;
    justify-content: center;
    margin-top: -6rem;
  }
}
