.faq-page {
  & .faq-accordation {
    max-width: 80rem;
    width: 90%;
    margin: 10em auto;
    border-radius: 1.2rem;
    border: 0.2rem solid #333;
    overflow: hidden;
    button.accordion {
      width: 100%;
      background-color: whitesmoke;
      border: none;
      outline: none;
      text-align: left;
      padding: 1.5rem 2rem;
      font-size: 1.8rem;
      color: #333;
      background-color: white;
      cursor: pointer;
      transition: background-color 0.2s linear;
    }

    button.accordion::before {
      flex-direction: row;
      margin-right: 0.3rem;
      @extend %fa-icon;
      @extend .fa-solid;
      content: fa-content($fa-var-plus);
      padding: 0.2rem;
      background-color: #333;
      color: white;
      font-size: 1.8rem;
      //   float: right;
    }

    button.accordion.accordion-is-open::before {
      @extend %fa-icon;
      @extend .fa-solid;
      content: fa-content($fa-var-minus);
      background-color: yellow;
      color: black;
    }

    button.accordion.accordion-is-open {
      background-color: rgb(68, 67, 67);
      color: white;
    }

    & .accordion-icon {
      border-radius: 0.2rem;
      padding: 0.2rem;
      width: 1.5rem;
      height: 1.5rem;
      font-size: 1.3rem;
      background-color: rgb(54, 52, 52);
      color: white;
    }

    .accordion-content {
      background-color: white;
      border-left: 0.1rem solid whitesmoke;
      border-right: 0.1rem solid whitesmoke;
      padding: 0 2rem;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.2s ease-in-out;
    }
  }
  & .map-sec {
    position: relative;
    // overflow: hidden;
    // margin-bottom: 20rem;
    & .map-image {
      width: 90%;
      margin: 0 auto;
    }
    & .contact-box {
      backface-visibility: hidden;
      background-color: white;
      z-index: 99;
      padding: 2rem;
      border-radius: 1.2rem;
      width: 80vw;
      margin: -10rem auto 0 auto;
      @include respond(laptop-md) {
        margin-top: -4rem;
      }
      box-shadow: 0 0.3rem 0.9rem 0.5rem rgba(0, 0, 0, 0.678);
      -webkit-box-shadow: 0 0.3rem 0.9rem 0.5rem rgba(0, 0, 0, 0.678);
      -moz-box-shadow: 0 0.3rem 0.9rem 0.5rem rgba(0, 0, 0, 0.678);
      list-style-type: none;
      & > * {
        display: flex;
        margin-top: 2rem;
        & img {
          width: 2rem;
          height: 2rem;
          margin-right: 1rem;
        }
      }
    }
  }
}
