.job-part-sec {
  & .open-position {
    border-bottom: 0.2rem solid gray;
    &_button {
      border-radius: 1.2rem;
      width: 15rem;
      height: 4rem;
      font-size: 2rem;
      background-color: white;
    }
    &_footer {
      margin: 4rem auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 50%;
      &_heading {
        width: fit-content;
      }
    }
  }
}

.contactUsForm {
  font-size: 2.5em;
  .attachFiles {
    position: relative;
    input {
      position: absolute;
      opacity: 0;
      top: 0;
    }
  }
}

.corporate-values-sec {
  display: flex;
  align-items: center;
  justify-content: center;
  & > :not(:last-child) {
    // margin-right: 3rem;
  }
  & > * {
    margin-bottom: 5rem;
  }
}
