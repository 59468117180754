.disableScroll {
  overflow: hidden;
}

.custom-dropdown-container:hover {
  & > .custom-dropdown {
    display: flex;
  }
}
.custom-dropdown {
  &-container {
    position: relative;
  }

  display: none;
  gap: 1rem;
  position: absolute;
  width: max-content;
  top: 100%;
  left: 0;
  z-index: 1000;
  background-color: white;
  border-radius: 1rem;
  padding: 0.75rem 1.5rem;
  box-shadow: 0 0.8rem 1.8rem 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s opacity;

  &-menu {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  &-item {
    color: black;
    display: flex;
    gap: 0.25rem;
    align-items: center;
    cursor: pointer;
    border-radius: 1rem;
    transition: 0.3s;

    &:hover {
      background-color: #f5f5f5;
    }

    &-img {
      width: 3rem;
    }
  }
}

// mobile sidebar
/* Google Fonts Import Link */
.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 20rem !important;
  padding: 4rem 0 1.5rem 0;
  overflow: auto;
  background: white;
  z-index: 98;
  transition: all 0.5s ease;
}
.sidebar.close {
  width: 0 !important;
}
.sidebar .logo-details {
  height: 6rem;
  width: 100%;
  display: flex;
  align-items: center;
}
.sidebar .logo-details i {
  font-size: 3rem;
  color: #11101d;
  height: 5rem;
  min-width: 7.8rem;
  text-align: center;
  line-height: 5rem;
}
.sidebar .logo-details .logo_name {
  font-size: 2.2rem;
  color: #11101d;
  font-weight: 600;
  transition: 0.3s ease;
  transition-delay: 0.1s;
}
.sidebar.close .logo-details .logo_name {
  transition-delay: 0s;
  opacity: 0;
  pointer-events: none;
}
// .sidebar .nav-links {
//   height: 100%;
//   padding: 4rem 0 1.5rem 0;
//   overflow: auto;
// }
.sidebar.close .nav-links {
  overflow: visible;
}
.sidebar .nav-links::-webkit-scrollbar {
  display: none;
}
.sidebar .nav-links li {
  position: relative;
  list-style: none;
  transition: all 0.4s ease;
}
// .sidebar .nav-links li:hover {
//   background: #1d1b31;
// }
.sidebar .nav-links li .iocn-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
}
.sidebar.close .nav-links li .iocn-link {
  display: block;
}
.sidebar .nav-links li svg {
  height: 2rem;
  min-width: 2rem;
  text-align: center;
  line-height: 5rem;
  color: #11101d;
  font-size: 2rem;
  cursor: pointer;
  transition: all 0.3s ease;
}
.sidebar .nav-links li.showMenu .sidebar-dropdown-arrow {
  transform: rotate(-180deg);
}

.sidebar .nav-links li a {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.sidebar .nav-links li a .link_name {
  font-size: 1.8rem;
  font-weight: 400;
  color: #11101d;
  transition: all 0.4s ease;
}
.sidebar.close .nav-links li a .link_name {
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links li .sub-menu {
  padding: 0.6rem 0.6rem 0.6rem 0.6rem;
  margin-top: -1rem;
  background: white;
  display: none;
  & > :not(:last-child) {
    border-bottom: 0.1rem solid #11101d;
  }
}
.sidebar .nav-links li.showMenu .sub-menu {
  display: block;
}
.sidebar .nav-links li .sub-menu a {
  color: #11101d;
  font-size: 1.5rem;
  padding: 0.5rem 0;
  white-space: nowrap;
  opacity: 0.6;
  transition: all 0.3s ease;
}
.sidebar .nav-links li .sub-menu a:hover {
  opacity: 1;
}
.sidebar.close .nav-links li .sub-menu {
  position: absolute;
  left: 100%;
  top: -1rem;
  margin-top: 0;
  padding: 1rem 2rem;
  border-radius: 0 0.6rem 0.6rem 0;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s;
}
.sidebar.close .nav-links li:hover .sub-menu {
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
}
.sidebar .nav-links li .sub-menu .link_name {
  display: none;
}
.sidebar.close .nav-links li .sub-menu .link_name {
  font-size: 1.8rem;
  opacity: 1;
  display: block;
}
.sidebar .nav-links li .sub-menu.blank {
  opacity: 1;
  pointer-events: auto;
  padding: 0.3rem 2rem 0.6rem 1.8rem;
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links li:hover .sub-menu.blank {
  top: 50%;
  transform: translateY(-50%);
}
.sidebar .profile-details {
  position: fixed;
  bottom: 0;
  width: 26rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1d1b31;
  padding: 1.2rem 0;
  transition: all 0.5s ease;
}
.sidebar.close .profile-details {
  background: none;
}
.sidebar.close .profile-details {
  width: 7.8rem;
}
.sidebar .profile-details .profile-content {
  display: flex;
  align-items: center;
}
.sidebar .profile-details img {
  height: 5.3rem;
  width: 5.3rem;
  object-fit: cover;
  border-radius: 1.8rem;
  margin: 0 1.4rem 0 1.2rem;
  background: #1d1b31;
  transition: all 0.5s ease;
}
.sidebar.close .profile-details img {
  padding: 1rem;
}
.sidebar .profile-details .profile_name,
.sidebar .profile-details .job {
  color: #11101d;
  font-size: 1.8rem;
  font-weight: 500;
  white-space: nowrap;
}
.sidebar.close .profile-details i,
.sidebar.close .profile-details .profile_name,
.sidebar.close .profile-details .job {
  display: none;
}
.sidebar .profile-details .job {
  font-size: 1.2rem;
}
.home-section {
  position: relative;
  background: #e4e9f7;
  height: 100vh;
  left: 26rem;
  width: calc(100% - 26rem);
  transition: all 0.5s ease;
}
.sidebar.close ~ .home-section {
  left: 7.8rem;
  width: calc(100% - 7.8rem);
}
.home-section .home-content {
  height: 6rem;
  display: flex;
  align-items: center;
}
.home-section .home-content .bx-menu,
.home-section .home-content .text {
  color: #11101d;
  font-size: 3.5rem;
}
.home-section .home-content .bx-menu {
  margin: 0 1.5rem;
  cursor: pointer;
}
.home-section .home-content .text {
  font-size: 2.6rem;
  font-weight: 600;
}
@include respond(phone) {
  .sidebar.close .nav-links li .sub-menu {
    display: none;
  }
  .sidebar {
    width: 7.8rem;
  }
  .sidebar.close {
    width: 0;
  }
  .home-section {
    left: 7.8rem;
    width: calc(100% - 7.8rem);
    z-index: 100;
  }
  .sidebar.close ~ .home-section {
    width: 100%;
    left: 0;
  }
}
